.bv-container .container {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
}
.bv-video {
    width: 95%;
    border-radius: 64px;
    margin-top: -160px; 
    z-index: 1;
}

@media (max-width: 1024px) {
    .bv-video {
        margin-top: 1rem;
    }
}

@media (max-width: 767.98px) {
    .bv-container {
        display: none;
    }
}

@media (min-width: 768px) {
    .bv-video {
        margin-top: -3rem;
        width: 85%;
    }
}
