@media (min-width: 768px) {
    .col-md-2 {
        flex: 0 0 auto;
        width: 20% !important;
    }
}

.back-button{
    border: 2px solid rgb(44, 19, 231);
    background-color: transparent;
    color: rgb(44, 19, 231);
}
.back-button:hover{
    border: 2px solid rgb(44, 19, 231);
    background-color: rgb(44, 19, 231);
    color: white;
}

.blog-image-container {
    position: relative;
    width: 100%;
    height: 60vh;
    overflow: hidden;
  }
  
  .blog-image-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    z-index: 1;
  }
  
  .blog-images {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 0;
  }  

.image-title{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: black;
}

.blog-home{
    text-decoration: none;
    color: white;
}
.blog-image-containers {
    position: relative;
    width: 100%;
    height: 50vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .blog-image-containers::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    z-index: 1;
  }
  
  .blog-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 0;
  }
  
  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }
  .card-title {
    color: white !important;
  }
  .image-titles {
    color: white;
    padding: 20px 30px;
    border-radius: 5px;
    text-align: center;
    z-index: 10;
  }

  .multi-line-ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
    max-height: 3.4em;
  }
  
