.wwd-wrapper {
    position: relative;
}

.wwd-container {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    justify-content: center;
    padding: 16px;
}

.wwd-head {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    justify-content: center;
    width: 75%;
}

.wwd-blocks {
    width: 100%;
    display: flex;
    gap: 32px;
}

.wwd-block {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
    padding: 32px;
    text-align: center;
    box-shadow: var(--shadow);
    border-radius: 32px;
}

.block-features {
    margin-top: 16px;
    display: flex;
    gap: 16px;
}

.block-feature {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 16px;
    border: 1px solid hsla(0, 0%, 59%, 0.17);
    border-radius: 16px;
    padding: 16px;
    color: var(--title-color);
    font-size: 17.6px;
    font-weight: 500;
    height: fit-content;
}

.wwd-block:nth-child(1) > .block-features > :nth-child(2) {
    margin-top: 48px;
}

.wwd-block:nth-of-type(2) > .block-features > :nth-child(1),
.wwd-block:nth-of-type(2) > .block-features > :nth-child(3) {
    margin-top: 32px;
}

.wwd-support {
    display: flex;
    padding: 32px;
    box-shadow: var(--shadow);
    border-radius: 16px;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    gap: 10px;
    background: white;
}

.wwd-support > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.wwd-support > :nth-child(1) {
    flex: 1;
}

.wwd-support > :nth-child(2) {
    flex: 1.6;
}
.titles {
    font-size: 2.6rem;
    color: var(--title-color);
    font-weight: bold;
    text-align: center;
    line-height: 3rem;
    text-transform: capitalize;
  }

@media (max-width: 990px) {
    .wwd-blocks {
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .wwd-support {
        flex-direction: column;
    }
    .titles {
        font-size: 1.8rem;
        line-height: 2.5rem;
    }
    .des{
        font-size: 18.4px;
    }
    
    .wwd-container {
        margin-top: 60px;
    }
}

  @media (max-width: 640px) {
    .titles {
        font-size: 1.5rem;
        line-height: 2rem;
    }
  }

@media (max-width: 576px) {
    .wwd-block {
        margin: auto;
    }

    .block-features {
        flex-direction: column;
    }

    .block-feature {
        margin-top: 0px !important;
        align-items: center;
    }
}