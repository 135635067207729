.t-wrapper .container {
    padding-top: 4rem;
    padding-bottom: 5rem;
}

.t-container{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
}

.t-head {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    max-width: 50rem;
}

.slick-slider {
    margin-top: 5rem;
}

.slick-track {
    padding: 4rem 0rem;
}

.slick-slide > div {
    padding: 0 20px;
}

.slick-dots {
    bottom: 0px!important;
}

.comment {
    display: flex !important;
    flex-direction: column;
    border-radius: 20px;
    position: relative;
    box-sizing: border-box;
    padding: 2rem;
    gap: 3rem;
}
.c-content {
    margin-top: 30px;
    height: 200px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 2rem;
}

.c-content span {
    font-size: 1rem;
    line-height: 1.5rem;
}

.c-info {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.c-avatar {
    background-color: #877eb5;
    color: white;
    font-size: 1.1rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
}

.c-person {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.c-person > :nth-child(1) {
    font-weight: bold;
}

.c-person > :nth-child(2) {
    font-weight: bold;
    color: rgba(128, 128, 128, 0.688);
    font-size: 0.8rem;
}

@media (min-width: 1280px) {
    .slick-slide.slick-active.slick-current + .slick-slide .comment {
        transition: all 1000ms ease-out;
        scale: 1.1;
        transform: translateY(-30px);
        box-shadow: var(--shadow);
    }
}

@media screen and (min-width: 1001px) and (max-width: 1199.98px){
    .c-info {
        padding-top: 35px;
    }
}

@media screen and (max-width: 364.98px){
    .comment {
        padding: 0rem;
    }
    .c-info {
        padding-top: 20px;
    }
    .c-content {
        margin-top: 0px;
    }
}


@media screen and (min-width: 365px) and (max-width: 768px){
    .comment {
        padding: 0rem 2rem;
    }
    .c-content {
        margin-top: 0px;
    }
}


@media screen and (max-width: 349.98px){
    .title {
        font-size: 29px;
    }
}
