.od-wrapper {
    padding-top: 45px;
    padding-bottom: 45px;
    /* margin-top: 3rem; */
}

.od-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.od-head {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
}

.od-head .tag {
    color: white;
}

.od-head .text {
    color: white;
    text-align: center;
    max-width: 65%;
}

.od-features {
    display: flex;
    gap: 32px;
}

.od-feature {
    flex: 1;
    background-color: white;
    box-shadow: var(--shadow);
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    padding: 32px;
    border-radius: 16px;
}

.od-feature .text {
    text-align: center;
}

@media (max-width: 989.98px) {
    .od-features {
        flex-direction: column;
    }
}

@media (max-width: 590px) {
    .od-feature {
        width: 80%;
        margin: auto;
    }
}