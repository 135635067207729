*{
  font-family: "Poppins", sans-serif;
}

.carousel-indicators {
  display: none !important;
}

.hero-images{
  width: 100%;
  padding: 0px 0px;
}

.image-container{
  height: 100vh;
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  z-index: 1;
}

.imageDetail-title{
  font-weight: 1000;
  font-size: 45px;
  line-height: 1.3;
}

.imageDetail-para{
  font-size: 22px;
  line-height: 1.7;
  font-weight: 400;
  color: #5B5B5B;
}

.centered-image-container {
    display: flex;
    justify-content: center; 
    align-items: center;     
    margin: 20px 0px; 
  }
  
  .centered-image-container img {
    max-width: 80%;
    height: auto; 
  }

  /* ---------paralist---------- */

  .paralist-head{
    color: #009AD9;
    font-weight: bold;
  }

  .facheck{
    color: #0159D3;
  }
  
  /* -------------------Recent post--------------------- */


  .recent_post{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .link-text{
    text-decoration: none;
    color:black;
  }

  .link-text:hover{
    text-decoration: none;
    color:rgba(31, 26, 229, 1);
  }

  .date{
    color: gray;
  }

.date:hover{
  color: gray;
}

.recent-image{
  height: 100vh;
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recent-image:hover::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  z-index: 1;
}

  .link-heading{
    font-weight: 780;
    font-size: 20px;
    line-height: 1.4;
  }

  .recommed {
    background-image:linear-gradient(
      rgba(43, 45, 46, 0.658), /* Light blue color with opacity */
      rgba(74, 78, 79, 0.557) /* Light blue color with opacity */
  ),  url('../../assets/latestaccounting.webp');
    background-size: cover; /* Optional: Cover the entire element */
    background-position: center; /* Optional: Center the background image */
    background-repeat: no-repeat; /* Optional: Prevent repeating the image */
    color: white;
  }
 
  @media screen and (max-width: 991.98px) {
    .link-heading{
      font-weight: 780;
      font-size: 16px;
      line-height: 1.4;
    }
  }

  @media screen and (min-width:426px) and (max-width: 767.98px){
    .imageDetail-title{
      font-weight: 800;
      font-size: 26px;
      line-height: 1.3;
    }
    
    .imageDetail-para{
      font-size: 20px;
      line-height: 1.7;
      font-weight: 400;
      color: #5B5B5B;
    }
    
    .paralist-head{
      color: #009AD9;
      font-weight: bold;
    }
    ul{
      padding-left: 0px !important;
    }

    .recent_post{
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  @media screen and (max-width: 425.98px){
    .imageDetail-title{
      font-weight: 800;
      font-size: 20px;
      line-height: 1.3;
    }
    
    .imageDetail-para{
      font-size: 15px;
      line-height: 1.7;
      font-weight: 400;
      color: #5B5B5B;
    }
    
    .paralist-head{
      color: #009AD9;
      font-weight: bold;
    }
    ul{
      padding-left: 0px !important;
    }

    .recent_post{
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }