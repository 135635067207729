.contact-bg{
    background-color: #533fd7;
}
.contactus{
    color: white;
    font-family: '__Josefin_Sans_9583da', '__Josefin_Sans_Fallback_9583da';
    padding: 50px 100px 30px 100px;
}
.contactus h2{
    font-size: 40px !important;
}
.contactus h3{
    font-size: 20px !important;
}
.socialmedia{
    display: flex;
    gap: 16px;
    font-size: 32px;
    padding-top: 90px;
}
.contact-border{
    border: 1px solid white;
    border-radius: 5px;
    background-color: white;
    margin-top: -220px;
}
.contact-form{
    background-color: rgba(238, 237, 237, 0.954);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    
}
.envelope{
    font-size: 32px;
    background-color: #533fd7;
    color: white;
    border-radius: 2px;
}
.form-input{
    width: 100%;
    background-color: rgba(238, 237, 237, 0.954);
    border-radius: 3px;
    border: none;
    outline: none;
    font-size: 18px;
}
.message{
    width: 100%;
}
.form-row{
    background-color: #a7a5b87a;
}
.submit{
    width: 100%;
    font-size: 25px;
    color: white;
    background-color: #533fd7;
    border: none;
}
.next-step h3{
    font-size: 40px !important;

}
@media screen and (min-width: 791px) and (max-width: 991.98px){
    .contact-border{
        /* margin-top: -106px; */
    }
}
@media screen and (min-width: 768px) and (max-width: 790.98px){
    .contact-border{
        /* margin-top: -130px; */
    }
}
@media screen and (max-width: 767.98px){
    .contact-border{
        margin-top: 0px;
    }
    .contactus{
        text-align: center;
        /* padding: 60px 0px 30px 0px; */

    }
    .socialmedia{
        padding-top:10px;
        padding-bottom: 50px;
        justify-content: center;
    }
    .row>* {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        padding-right: 0px !important;
        padding-left: calc(var(--bs-gutter-x)* .5);
        margin-top: var(--bs-gutter-y);
    }
    .form-col{
        background-color: #a7a5b87a;
        padding-bottom: 20px
    }
}

.whatsapp-button{
    /* background-color: #128C7E; */
    padding-top: 3px;
    color: white;
    text-decoration: none;
  }