.fund {
    color: var(--dark-bg);
    background-color: var(--third-color);
    padding: 12.8px 25.6px;
    font-size: 14px;
    text-transform: capitalize;
    border-radius: 999px;
}
.nav-text{
    color: #221577;
    font-size: 12px;
    font-weight: bold;
}
.custom-navbar {
    position: sticky;
    top: 0;
    z-index: 4;
    background: white;
    width: 100%;
  }
  
  