.f-wrapper .container {
    margin-top: 0px;
    padding-top: 80px;
    padding-bottom: 32px;
}

.f-wrapper {
    background-color: var(--primary-color);
}

.f-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.f-container .title {
    color: white;
}

.f-container .emailBox {
    max-width: 576px;
}

.f-container hr {
    border-color: var(--border-color-light);
    width: 100%;
}

.f-menu {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    color: white;
}

.f-menu span {
    color: white;
    text-transform: uppercase;
    font-size: 14.4px;
    font-weight: bold;
}
.footer-link{
    text-decoration: none;
    color: white;
}

@media (max-width: 991.98px) {
    .f-menu {
        flex-direction: column;
        gap: 32px;
        align-items: center;
        text-align: center;
    }
}

.tellto{
    text-decoration: none;
    color: white;
}