.getaquote{
    background-color:  #890989;
    color: white;
    border: none;
}
.wide-range{
    /* background-color: black; */
    width: 100%;
    height: 47vh;
    /* height: 100vh; Full viewport height */
    background: 
        linear-gradient(
            rgba(0, 0, 0, 0.676), /* Start color with transparency */
            rgba(0, 0, 0, 0.687)  /* End color with transparency */
        ),
        url('/src/assets/corporate.webp') no-repeat center center/cover; /* Background image */
    color: white;
}
.wide-range-Business{
    background-color: #970c97;
}
.next{
    text-align: right;
}

/* Grid */
.grid-bg {
    background-color: rgba(149, 239, 153, 0.224);
    --bs-gutter-x: 0 !important;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;
    gap: 25px; /* Adjust the gap between columns if needed */
}

.grid-item {
    background-color: white; /* For demonstration */
    padding: 30px;
    text-align: center;
}

@media (max-width: 1200px) {
    .grid-container {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 992px) {
    .grid-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .grid-container {
        grid-template-columns: repeat(2, 1fr);
        justify-content: center; /* Center the items horizontally */
    }
}

@media (max-width: 576px) {
    .grid-container {
        grid-template-columns: 1fr;
        justify-items: center; /* Center the items horizontally */
    }

    .grid-item {
        width: 100%; /* Ensure the item takes the full width of the container */
        max-width: 300px; /* Optionally, set a max-width for better appearance */
    }
}

@media screen and (max-width: 1199.98px){
    .wide-range{
        height: 55vh;
    }
}

@media screen and (max-width: 991.98px){
    .wide-range{
        height: 47vh;
    }
}

@media screen and (min-width: 2000px){
    .wide-range{
        height: 25vh;
    }
}
  
  /* Grid */

  
  .maximsAuditors{
    background-color: black;
    color: white;
  }
  
  .corporate-testimonial{
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  }
  .slick-track{
    padding-top: 0px;
  }
  .slick-slider{
    margin-top: 3rem !important;
  }
  .star-rating{
    color: rgb(255, 208, 0);
  }